import React from 'react'
// import Layout from '../../Layout/Layout';
import TopSection from '../../Components/Listing/Detail/TopSection';
import Main from '../../Components/Listing/Detail/Main';
import DetailBottom01 from '../../Components/Listing/Detail/DetailBottom/DetailBottom01';
import Layout from '../../Layout/Layout';
import DetailBottom02 from '../../Components/Listing/Detail/DetailBottom/DetailBottom02';
import DetailBottom03 from '../../Components/Listing/Detail/DetailBottom/DetailBottom03';


const Detail = () => {
    return (
        <>
            <Layout>
                <TopSection />
                <Main />
                <DetailBottom01 />
                <DetailBottom02 />
                <DetailBottom03 />
            </Layout>
        </>
    )
}

export default Detail


