import React from "react";
import { Card } from "react-bootstrap";
import { FaLongArrowAltRight } from "react-icons/fa";
const DetailBottom03 = () => {
  return (
    <>
      <h1 className="text-center text-black fw-bold my-4">
        View more properties in
      </h1>
      <div className="container">
        <div className="row px-lg-0 px-md-0 px-2">
          <div className="col-lg-8 mx-auto">
            <div className="row">
              <div className="col-lg-6">
                <div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <div>
                      <p>Greater London</p>
                    </div>
                    <div>
                      <FaLongArrowAltRight />
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <div>
                      <p>Greater London</p>
                    </div>
                    <div>
                      <FaLongArrowAltRight />
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <div>
                      <p>Greater London</p>
                    </div>
                    <div>
                      <FaLongArrowAltRight />
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
              <div className="col-lg-6">
                <hr />
                <div className="d-flex justify-content-between">
                  <div>
                    <p>Greater London</p>
                  </div>
                  <div>
                    <FaLongArrowAltRight />
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <div>
                    <p>Greater London</p>
                  </div>
                  <div>
                    <FaLongArrowAltRight />
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid my-5">
        <div className="my-4">
          <h1 className="text-center text-black fw-bold">
            Related Publications/Reports
          </h1>
          <p className="text-center">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus,
            doloremque.
          </p>
        </div>
        <div className="row px-lg-0 px-md-0">
          <div className="col-lg-4 mx-auto">
            <Card style={{ border: "none" }}>
              <Card.Img
                height={280}
                variant="top"
                src="/assets/Images/card.png"
              />
              <Card.Body>
                <Card.Title>
                  <h2 className="fw-bold fs-2">The View Issue 2</h2>
                </Card.Title>
                <Card.Text>
                  We understand that buying, selling or letting a home is a big
                  decision.
                </Card.Text>
                <div>
                    Read more
                <FaLongArrowAltRight className="ms-2" size={24} />
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailBottom03;
