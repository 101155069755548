import React from 'react'
import { IoIosArrowBack,IoIosArrowForward  } from "react-icons/io";

const TopSection = () => {
    return (
        <>
            <section className='p-3 border-bottom-1'>
                <div className='container'>
                <div className='d-flex justify-content-between align-items-center'>
                    <p><span className='me-3'><IoIosArrowBack /></span>Back to results</p>
                    <p>Property  1 of 3822</p>
                    <p>Next<span className='ms-3'><IoIosArrowForward  /></span></p>
                </div>
                </div>
            </section>
        </>
    )
}

export default TopSection