import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";

const HomeHeader = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const handleNavbarToggle = () => {
  setNavbarOpen(!navbarOpen);
  };
  return (
    <>
    <Navbar
      expand="lg"
      className={`container ${navbarOpen ? 'bg-white' : ''}`}
      onToggle={handleNavbarToggle}
    >
      <Container fluid>
        <Navbar.Brand href="#" className='mt-3'>
          <img src="\assets\Images\logo.png" alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" className='mt-4'>
          <span className={`${navbarOpen ? 'text-black' : 'text-white'}`}><GiHamburgerMenu size={32} /></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
          >
          </Nav>
          <Form className="d-flex">
            <Nav
              className="me-auto my-2 my-lg-0 w-100"
            >
              <Link to="/" className={`fs-5 fw-bold mx-4 text-decoration-none my-2 ${navbarOpen ? 'text-black' : 'text-white'}`}>Home</Link>
              <Link to="/listing" className={`fs-5 fw-bold mx-4 text-decoration-none my-2 ${navbarOpen ? 'text-black' : 'text-white'}`}>Buy</Link>
              <Link to="/listing" className={`fs-5 fw-bold mx-4 text-decoration-none my-2 ${navbarOpen ? 'text-black' : 'text-white'}`}>Rent</Link>
              <Link to="/" className={`fs-5 fw-bold mx-4 text-decoration-none my-2 ${navbarOpen ? 'text-black' : 'text-white'}`}>About us</Link>
            </Nav>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    </>
  )
}

export default HomeHeader
