/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { IoMdInformationCircleOutline } from "react-icons/io";

const BottomSection = () => {
    return (
        <>
            <section>
                <div className='container-fluid my-3'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-6 d-flex justify-content-center align-items-start'>

                            <IoMdInformationCircleOutline fontSize={30} />
                            <p>Please note all distances and measurements are approximate and the map location is not the exact location of the property</p>
                        </div>
                        <div className='col-lg-6'>
                            <div class="b-pagination-outer">

                                <ul id="border-pagination">
                                    <li><a class="" href="#">«</a></li>
                                    <li><a href="#">1</a></li>
                                    <li><a href="#" class="active">2</a></li>
                                    <li><a href="#">3</a></li>
                                    <li><a href="#">4</a></li>
                                    <li><a href="#">5</a></li>
                                    <li><a href="#">6</a></li>
                                    <li><a href="#">7</a></li>
                                    <li><a href="#">»</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BottomSection