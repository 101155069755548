/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react'
import ListingCard from './Card'
import { property } from './Data'
import {useLocation} from "react-router-dom"


const Main = ({ Data }) => {
    const location = useLocation()
    const HomeData = location.state; 
    const [data, setData] = useState([])

    useEffect(() => {
        if (Data.length > 0) {
            setData(Data)
        }else if (HomeData?.length > 0){
            setData(HomeData)
        }else{
            setData(property)
        }
    }, [Data,HomeData])

    console.log(data, "property")
    return (
        <>
            <section className='mt-5'>
                <div className='container-fluid border-0'>
                    <div className='row'>
                        <div className='col-lg-12 pb-5 bg-lightgray'>
                            <div className='row'>
                                {data?.map((e) => {
                                    return (
                                        <>
                                            <div className='col-lg-4 col-md-6 col-12'>
                                                <ListingCard data={e} />
                                            </div>
                                        </>
                                    )
                                })}

                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main