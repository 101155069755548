import React from "react";
import Card from "react-bootstrap/Card";

const HomeSection01 = () => {
  return (
    <>
      <div className="container">
        <div className="row my-5">
          <div className="col-lg-4 my-4">
            <Card style={{ border: "none" }}>
              <Card.Img
                height={280}
                variant="top"
                src="/assets/Images/card.png"
              />
              <Card.Body>
                <Card.Title>
                  <h2 className="fw-bold fs-2">Residential</h2>
                </Card.Title>
                <Card.Text>
                  We understand that buying, selling or letting a home is a big
                  decision. That’s why we’re here to guide you through the
                  process, giving expert advice and insight from first offer to
                  final completion.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-4 my-4">
            <Card style={{ border: "none" }}>
              <Card.Img
                height={280}
                variant="top"
                src="/assets/Images/card1.png"
              />
              <Card.Body>
                <Card.Title>
                  <h2 className="fw-bold fs-2">Commercial</h2>
                </Card.Title>
                <Card.Text>
                  Whether you’re interested in buying, renting, leasing,
                  investing or developing, our team is at the forefront of the
                  commercial property industry. Our knowledge spans sectors
                  ranging from student living to office space, hospitality and
                  retail – and much more.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-4 my-4">
            <Card style={{ border: "none" }}>
              <Card.Img
                height={280}
                variant="top"
                className="rounded"
                src="/assets/Images/card2.png"
              />
              <Card.Body>
                <Card.Title>
                  <h2 className="fw-bold fs-2">International</h2>
                </Card.Title>
                <Card.Text>
                  Purchasing a property overseas has its complexities. With our
                  global footprint and international experience, we’ll help you
                  navigate the nuances of an international property transaction
                  with confidence.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeSection01;
