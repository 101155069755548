import React from "react";
// import TopSection from "../../Components/Listing/TopSection";
import SerchFilter from "../../Components/Listing/SerchFilter";
// import Main from "../../Components/Listing/Main";
import BottomSection from "../../Components/Listing/BottomSection";
import Layout from "../../Layout/Layout";

const Listing = () => {
  return (
    <>
      <Layout>
        <section className="listing">
          {/* <TopSection /> */}
          <SerchFilter />
          {/* <Main /> */}
          <BottomSection />
        </section>
      </Layout>
    </>
  );
};

export default Listing;
