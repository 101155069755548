import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <>
      <section className='bg-black p-4 d-lg-block d-none'>
        <div className='container'>
          <div className='d-flex justify-content-between align-items-center '>
          <div className=''>
            <img src="\assets\Images\logo.png" alt="" />
            </div>
            <div className='d-flex justify-content-center align-items-center'>
              <Link to='/' className='pe-5 nav-link text-white'>Home</Link>
              <Link to='/listing' className='pe-5 nav-link text-white'>Buy</Link>
              <Link to='/listing' className='pe-5 nav-link text-white'>Rent</Link>
              <Link to='#' className='pe-5 nav-link text-white'>About Us</Link>
            </div>
           
          </div>
        </div>
      </section>
    </>
  )
}

export default Header