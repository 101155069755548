/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { FaFilter, FaSearch } from "react-icons/fa";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoGridOutline } from "react-icons/io5";
import { CiBoxList } from "react-icons/ci";
import Main from "../../Components/Listing/Main";
import { property } from './Data';
import Modal from 'react-bootstrap/Modal';


const SerchFilter = () => {

    const [Search, setSearch] = useState("")
    const [Price, setPrice] = useState("")
    const [Bedrooms, setBedrooms] = useState("")
    const [Bathrooms, setBathrooms] = useState("")
    const [Sqft, setSqft] = useState("")
    const [Type, setType] = useState("")
    const [Purchase, setPurchase] = useState("")
    const [Sold, setSold] = useState("")
    const [Result, setResult] = useState([])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleFilter = () => {
        const amount = Price.split("-")
        const Bedroom = Bedrooms.split("-")
        const Bathroom = Bathrooms.split("-")
        const SquareFt = Sqft.split("-")
        const result = property?.filter((e) => e?.type == Type || (e?.availablity == Purchase || e?.availablity == Sold) || (e.price == amount[0] || e.price == amount[1]) || (e.bedrooms == Bedroom[0] || e.bedrooms == Bedroom[1]) || (e.bathrooms == Bathroom[0] || e.bathrooms == Bathroom[1]) || (e.squareFeet == SquareFt[0] || e.squareFeet == SquareFt[1]) || e?.name == Search)
        setResult(result)
        console.log(result, "result")
    }
    const handleClear = () => {

        setResult(property)
        setSearch("");
        setBathrooms("");
        setBedrooms("");
        setPrice("");
        setSqft("");
        setType("");
        setPurchase("");
        setSold("");
        handleClose()
    }
    return (
        <>
            <div className='container-fluid box-shadow'>
                <div className=''>
                    <div className='d-flex justify-content-between align-items-center p-3'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div class="form-check me-5">
                                    <input class="form-check-input" type="radio" name="purchase" value="Buy" id="flexRadioDefault1" onChange={(e) => setPurchase(e.target.value)} checked={Purchase === 'Buy'} />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Buy
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="purchase" id="flexRadioDefault1" value="Rent" onChange={(e) => setPurchase(e.target.value)} checked={Purchase === 'Rent'} />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Rent
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className='d-flex'>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="flexCheckDefault" value="Sold" onChange={(e) => setSold(e.target.value)} checked={Sold === 'Sold'} />
                                <label class="form-check-label" for="flexCheckDefault">
                                    Include sold properties
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <label>Search</label>
                            <div className='d-flex justify-content-between'>
                                <input type='search' className='w-100 p-2 border border-secondary' placeholder='search' value={Search} onChange={(e) => setSearch(e.target.value)} /><span>
                                    <select class="w-100 p-3  border border-secondary" aria-label="Default select example" disabled>
                                        <option selected>This Area Only</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <label>Price</label>
                                    <select class="w-100 p-3  border border-secondary" aria-label="Default select example" onChange={(e) => setPrice(e.target.value)}>
                                        <option selected>Min - Max</option>
                                        <option value="250000-500000">£ 250000 - £ 500000</option>
                                        <option value="750000-1000000">£ 750000 - £ 1000000</option>
                                        <option value="1250000-1500000">£ 1250000 - £ 1500000</option>
                                        <option value="1750000-2000000">£ 1750000 - £ 2000000</option>
                                    </select>
                                </div>
                                <div className='col-lg-3'>
                                    <label>Bedrooms</label>
                                    <select class="w-100 p-3  border border-secondary" aria-label="Default select example" onChange={(e) => setBedrooms(e.target.value)}>
                                        <option selected>Min - Max</option>
                                        <option value="1-2">1 - 2</option>
                                        <option value="3-4">3 - 4</option>
                                        <option value="5-6">5 - 6</option>
                                    </select>
                                </div>
                                <div className='col-lg-3'>
                                    <label>Property Type</label>
                                    <select class="w-100 p-3  border border-secondary" aria-label="Default select example" onChange={(e) => setType(e.target.value)}>
                                        <option selected>Property Type</option>
                                        <option value="Studio">Studio</option>
                                        <option value="Apartment">Apartment</option>
                                        <option value="House">House</option>
                                        <option value="Flat">Flat</option>
                                        <option value="Cottage">Cottage</option>
                                        <option value="Penthouse">Penthouse</option>
                                        <option value="Villa">Villa</option>
                                        <option value="Mansion">Mansion</option>
                                    </select>
                                </div>
                                <div className='col-lg-1 mt-4 px-0 '>
                                    <div className='d-flex justify-content-center align-items-center border border-dark py-3'>
                                        <FaFilter color='black' fontSize={20} onClick={handleShow} style={{ margin: '0 auto', display: 'block' }} />
                                    </div>
                                    <div className='w-100'>
                                        <div
                                            className="modal show"
                                            style={{ display: 'block', position: 'initial' }}
                                        >
                                            <Modal show={show} centered>
                                                <Modal.Header closeButton onHide={handleClose}>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <label className='text-center w-100'>Bathrooms</label>
                                                    <select class="w-100 p-3  border border-secondary" aria-label="Default select example" onChange={(e) => setBathrooms(e.target.value)}>
                                                        <option selected>Min - Max</option>
                                                        <option value="1">1+</option>
                                                        <option value="2">2+</option>
                                                        <option value="3">3+</option>
                                                        <option value="4">4+</option>
                                                        <option value="5">5+</option>
                                                    </select>

                                                    <label className='text-center w-100'>Floor Area</label>

                                                    <select class="w-100 p-3  border border-secondary" aria-label="Default select example" onChange={(e) => setSqft(e.target.value)}>
                                                        <option selected>Min - Max</option>
                                                        <option value="250-500">250 - 500</option>
                                                        <option value="750-1000">750 - 1000</option>
                                                        <option value="1250-1500">1250 - 1500</option>
                                                        <option value="1750-2250">1750 - 2250</option>
                                                        <option value="2750-3250">2750 - 3250</option>
                                                        <option value="3750-4250">3750 - 4250</option>
                                                        <option value="4750-5250">4750 - 5250</option>
                                                        <option value="5750-6250">5750 - 6250</option>
                                                        <option value="6750-7250">6750 - 7250</option>
                                                        <option value="7750-8750">7750 - 8750</option>
                                                        <option value="9250-9750">9250 - 9750</option>
                                                        <option value="10250">10250</option>
                                                    </select>
                                                </Modal.Body>

                                                <Modal.Footer>
                                                    <div className='w-100 p-3  border border-secondary d-flex justify-content-center align-items-center theme-bg-color text-white' onClick={handleFilter}>
                                                        Update
                                                    </div>
                                                    <div className='w-100 p-3  border border-secondary d-flex justify-content-center align-items-center bg-secondary' onClick={handleClear}>
                                                        Clear
                                                    </div>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-2 mt-1'>
                                    <label></label>
                                    <div className='w-100 p-3  border border-secondary d-flex justify-content-center align-items-center bg-secondary' onClick={handleFilter}>
                                        <FaSearch />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' p-3 d-lg-block d-none'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <p>1-48 of 3822 Properties for sale in UK</p>
                            </div>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <div className='col-lg-4 p-1 '>
                                        <h6>Create an email alert<span className='ms-2'><IoIosNotificationsOutline /></span> | </h6>
                                    </div>
                                    <div className='col-lg-5 d-flex p-1 '>
                                        <span>Sort By:</span>
                                        <select class="w-50  border-0" aria-label="Default select example" disabled>
                                            <option selected>This Area Only</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select> |
                                    </div>
                                    <div className='col-lg-1 d-flex justify-contnet-start align-items-center'>
                                        <IoGridOutline />
                                    </div>
                                    <div className='col-lg-1'>
                                        <CiBoxList />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Main Data={Result} />
        </>
    )
}

export default SerchFilter