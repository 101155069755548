/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "./home.css";
import { FiMapPin } from "react-icons/fi";
import { property } from "../Listing/Data";
import {useNavigate} from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate()
  const [Search, setSearch] = useState("")

  const handleFilter = () => {
    const result = property?.filter((e) =>e?.zipCode == Search || e?.name == Search)
    console.log(result, "result")
    navigate('/listing',{
      state: result
    })
}

  return (
    <>
      <div className="container home">
        <div className="row d-flex justify-content-center align-items-center w-100">
          <div className="col-lg-12 mx-auto text-center">
            <h1>Your partners in property</h1>
            <p>Let us know how we can help</p>
            <div className="inputs">
              <div id="search-wrapper">
                <FiMapPin size={24} className="mx-2" />
                <input
                  type="text"
                  id="search"
                  placeholder="Search 5 Min Code :)"
                  onChange={(e)=>setSearch(e.target.value)}
                />
              </div>
              <div className="my-2 d-flex my-md-0">
                <button className="mx-2" onClick={handleFilter}>Buy</button>
                <button className="mx-2" onClick={handleFilter}>Rent</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto text-center">
            <h3 className="fw-bold pb-3 text-black">
              We’re here to help you take the next step in your property
              journey.
            </h3>
            <p className="pb-3">
              We believe in the power of partnership. We value long-term
              relationships and personal service. Truly understanding the people
              we work with is a crucial piece of the property puzzle; ensuring
              that every client is matched to the right advisors and expertise.
              Thanks to our vast global network, you’ll find us wherever you
              need us.
            </p>
            <p>
              We offer considered advice, expert market insight and an
              unparalleled knowledge of the property landscape – in your local
              area, across the UK, and around the world. Wherever life takes you
              next, we’re here to be your trusted advisors
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
