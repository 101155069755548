export const property = [
    {
        "id": 1,
        "name": "Modern City Apartment",
        "price": 350000,
        "squareFeet": 1200,
        "type": "Apartment",
        "availablity": "Buy",
        'zipCode': 8763,
        "bedrooms": 2,
        "bathrooms": 2,
        "livingRooms": 1,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 2,
        "name": "Cozy Suburban House",
        "price": 450000,
        "squareFeet": 2000,
        "type": "House",
        "availablity": "Rent",
        'zipCode': 3578,
        "bedrooms": 3,
        "bathrooms": 2.5,
        "livingRooms": 2,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 3,
        "name": "Luxury Penthouse",
        "price": 850000,
        "squareFeet": 3000,
        "type": "Penthouse",
        "availablity": "Sold",
        'zipCode': 8567,
        "bedrooms": 4,
        "bathrooms": 4,
        "livingRooms": 3,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 4,
        "name": "Rustic Country Cottage",
        "price": 250000,
        "squareFeet": 1500,
        "type": "Cottage",
        "availablity": "Buy",
        'zipCode': 5834,
        "bedrooms": 2,
        "bathrooms": 1.5,
        "livingRooms": 1,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 5,
        "name": "Beachfront Villa",
        "price": 1200000,
        "squareFeet": 4000,
        "type": "Villa",
        "availablity": "Rent",
        'zipCode': 3467,
        "bedrooms": 5,
        "bathrooms": 5,
        "livingRooms": 4,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 6,
        "name": "Urban Loft",
        "price": 550000,
        "squareFeet": 1800,
        "type": "Apartment",
        "availablity": "Sold",
        'zipCode': 8942,
        "bedrooms": 2,
        "bathrooms": 2,
        "livingRooms": 1,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 7,
        "name": "Mountain Retreat",
        "price": 380000,
        "squareFeet": 1600,
        "type": "House",
        "availablity": "Buy",
        'zipCode': 3763,
        "bedrooms": 3,
        "bathrooms": 2,
        "livingRooms": 2,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 8,
        "name": "Lakefront Mansion",
        "price": 1500000,
        "squareFeet": 5000,
        "type": "Mansion",
        "availablity": "Rent",
        'zipCode': 9753,
        "bedrooms": 6,
        "bathrooms": 6,
        "livingRooms": 5,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 9,
        "name": "Downtown Studio",
        "price": 220000,
        "squareFeet": 800,
        "type": "Studio",
        "availablity": "Sold",
        'zipCode': 1274,
        "bedrooms": 1,
        "bathrooms": 1,
        "livingRooms": 1,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 10,
        "name": "Golf Course Estate",
        "price": 950000,
        "squareFeet": 3500,
        "type": "Apartment",
        "availablity": "Buy",
        'zipCode': 1574,
        "bedrooms": 4,
        "bathrooms": 3.5,
        "livingRooms": 3,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 11,
        "name": "The Haven, Billingshurst, West Sussex, RH14.",
        "price": 4650000,
        "squareFeet": 3500,
        "type": "House",
        "availablity": "Rent",
        'zipCode': 6674,
        "bedrooms": 4,
        "bathrooms": 3,
        "livingRooms": 2,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 12,
        "name": "Hanwell Square, Boston Road, Hanwell, London W7 3SA",
        "price": 437500,
        "squareFeet": 3500,
        "type": "Flat",
        "availablity": "Sold",
        'zipCode': 4755,
        "bedrooms": 4,
        "bathrooms": 3,
        "livingRooms": 1,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 13,
        "name": "Warwick Road, Wolston, CV8",
        "price": 635000,
        "squareFeet": 1647,
        "type": "House",
        "availablity": "Buy",
        'zipCode': 4783,
        "bedrooms": 3,
        "bathrooms": 3,
        "livingRooms": 1,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 14,
        "name": "Arundel Gardens, London, W11",
        "price": 1000000,
        "squareFeet": 832,
        "type": "Flat",
        "availablity": "Rent",
        'zipCode': 7387,
        "bedrooms": 2,
        "bathrooms": 2,
        "livingRooms": 1,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    },
    {
        "id": 15,
        "name": "Fairacres, Cobham, Surrey, KT11",
        "price": 3295000,
        "squareFeet": 5491,
        "type": "House",
        "availablity": "Sold",
        'zipCode': 7387,
        "bedrooms": 6,
        "bathrooms": 4,
        "livingRooms": 8,
        "images": [
            "./assets/Images/Slider/image-1.png",
            "./assets/Images/Slider/image-3.png",
        ]
    }
    // Add more properties as needed...
]