
import { HashRouter, Routes, Route } from 'react-router-dom';
import Listing from './Pages/Listings/Listing';
import Detail from './Pages/Listings/Detail';
import Home from './Pages/Home/Home';

function App() {
  return (
    <HashRouter>
      {/* <Layout> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/detail" element={<Detail />} />
          <Route path="/listing" element={<Listing />} />
        </Routes>
      {/* </Layout> */}
    </HashRouter>
  );
}

export default App;
