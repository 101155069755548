import React from 'react'

const HomeSection02 = () => {
  return (
    <>
      <div className="container home4">
        <div className="row">
            <div className="col-lg-4 m-0 m-md-5 m-lg-5">
                <h2 className='text-white fw-bold px-lg-0 px-md-0 px-4'>Cut through the noise</h2>
                <p className='text-white px-lg-0 px-md-0 px-4'>From cutting-edge research to inspiring editorial, our reports and publications are here to keep you up to speed with the ever-changing world of property
                From cutting-edge research publications are here to keep you up to speed with the ever-changing world of property.</p>
            </div>
        </div>
      </div>
      <div className="container home4-page">
        <div className="row my-5">
          <div className="col-lg-6 d-flex justify-content-center align-items-center">
            <div className='d-flex flex-column justify-content-center'>
            <h1 className='fw-bold px-lg-0 px-md-0 px-4'>Value your home</h1>
            <p className='px-lg-0 px-md-0 px-4'>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
            <button className='mx-lg-0 mx-md-0 mx-4'>Get an instant valudation</button>
            </div>
          </div>
          <div className="col-lg-6 my-5 px-lg-0 px-md-0 px-4">
            <img src="/assets/Images/r_block_image.png" className='w-100' alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeSection02
