import React from 'react'
import { TiTick } from "react-icons/ti";


const DetailBottom01 = () => {
  return (
    <>
      <div className="container">
        <div className="row my-5">
          <div className="col-lg-10 mx-auto">
            <div className='d-flex flex-lg-row flex-md-row flex-column justify-content-between align-items-center text-center'>
              <div>
                <p>Property Type</p>
                <h3 className='fw-bold fs-4'>Flat</h3>
              </div>
              <div>
                <p>Finishing</p>
                <h3 className='fw-bold fs-4'>Optional</h3>
              </div>
              <div>
                <p>Floor Area</p>
                <h3 className='fw-bold fs-4'>759 sqft</h3>
              </div>
              <div>
                <p>Council Tax Brand</p>
                <h3 className='fw-bold fs-4'>C</h3>
              </div>
              <div>
                <p>Deposite Payable</p>
                <h3 className='fw-bold fs-4'>3,916.15</h3>
              </div>
              <div>
                <p>Minimum Tinancy Length</p>
                <h3 className='fw-bold fs-4'>12 Months</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-4 px-lg-0 px-md-0 px-2">
          <div className="col-lg-6 mx-auto detail-bottom">
            <div className='d-flex flex-lg-row flex-md-row flex-column justify-content-between align-items-center'>
              <button className='border py-3 my-2'>EPC Chart</button>
              <button className='border py-3 my-2'>FrankPlan</button>
              <button className='border my-2 py-lg-0 py-md-0 py-3'>Virtual tour Resident's LifeStyle</button>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center my-5'>
        <img src="./assets/Images/logo.png" alt="" />
      </div>
      <h1 className='text-center text-black fw-bold'>Amenities</h1>
      <div className="container">
        <div className="row my-4 px-lg-0 px-md-0 px-2">
          <div className="col-lg-6 mx-auto">
            <div className='d-flex flex-column flex-lg-row flex-md-row justify-content-between align-items-center'>
              <div className='detail-bottom1'>
                <p><TiTick /> 2 Bedrooms</p>
                <p><TiTick /> Lite</p>
                <p><TiTick /> Concierge</p>
                <p><TiTick /> Upper Floor</p>
              </div>
              <div className='detail-bottom1'>
              <p><TiTick /> 2 Bedrooms</p>
                <p><TiTick /> Modern</p>
                <p><TiTick /> New Build</p>
                <p><TiTick /> Lateral</p>
              </div>
              <div className='detail-bottom1'>
              <p><TiTick /> 1 Reception Room</p>
                <p><TiTick /> Biocony</p>
                <p><TiTick /> Town And City</p>
                <p><TiTick /> Available With Zero</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </> 
  )
}

export default DetailBottom01
