/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import './detail.css'

const DetailBottom02 = () => {
  return (
    <>
   

      <h1 className="text-center text-black fw-bold my-4">Disclaimer</h1>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Mollitia nulla vitae libero sed blanditiis ut nam vel quae. Cum quas, temporibus hic culpa labore placeat rem iste laudantium sequi non dolore doloremque molestias consequatur deleniti, expedita, eaque magni magnam error mollitia commodi qui? Minus odio iste excepturi, nesciunt cum reprehenderit!</p>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Mollitia nulla vitae libero sed blanditiis ut nam vel quae. Cum quas, temporibus hic culpa labore placeat rem iste laudantium sequi non dolore doloremque molestias consequatur deleniti, expedita, eaque magni magnam error mollitia commodi qui? Minus odio iste excepturi, nesciunt cum reprehenderit!</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default DetailBottom02;
