import React from 'react'
import { IoIosBed } from "react-icons/io";
import { GiBathtub, GiSofa } from "react-icons/gi";
import { IoIosHeartEmpty } from "react-icons/io";
import { CiShare1 } from "react-icons/ci";

const Main = () => {
    return (
        <>
            <section className='mt-5'>
                <div className='container'>
                    <div className='d-flex justify-content-center align-items-center text-center flex-column'>
                        <h1 className='fw-bolder'>3 bedroom flat for sale in Harvist Road, Queen's Park, London, NW6</h1>
                        <p>Guide price £950,000</p>
                        <div className='row mt-2 justify-content-between'>
                            <div className='col-lg-4 col-md-4 col-4'>
                                <IoIosBed fontSize={50} />
                                <p>3</p>
                            </div>
                            <div className='col-lg-4 col-md-4 col-4'>
                                <GiBathtub fontSize={50} />
                                <p>2</p>
                            </div>
                            <div className='col-lg-4 col-md-4 col-4'>
                                <GiSofa fontSize={50} />
                                <p>1</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <img src='/assets/Images/detail.png' alt='' className='img-fluid' />
                            <div className='row mt-2'>
                                <div className='col-lg-6'>
                                    <img src='/assets/Images/detail1.png' alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-6'>
                                    <img src='/assets/Images/detail2.png' alt='' className='img-fluid' />
                                </div>
                                <div className='col-lg-12 mt-2'>
                                    <img src='/assets/Images/detail3.png' alt='' className='img-fluid' />
                                </div>
                            </div>
                            <div className='my-3 d-flex'>
                                <button className='text-dark border border-dark bg-transparent px-3 py-2 fw-bolder me-3'>
                                    View More Images
                                </button>
                                <button className='text-dark border border-dark bg-transparent px-3 py-2 fw-bolder'>
                                    Property Video
                                </button>
                            </div>
                        </div>
                        <div className='col-lg-4 px-lg-0 px-md-0 px-4'>
                            <select class="form-select" aria-label="Default select example">
                                <option selected>£1,850,000 <span className='ms-5'>GBP</span></option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                            <p>Monthly</p>
                            <h4 className='mt-3'>Reed Avenue, London, E3<br /> United Knigdom</h4>
                            <p className='mt-3'>Brand New Two Bed appartments in a high rise new development  with epic city views and access to a 27th floor roof  terrace, co-working space , private dinning area and on site  security</p>
                            <img src='/assets/Images/separator.svg' alt='' className='img-fluid' />
                            <p className='mt-5'>Property Ref: <span className='fw-bolder'>ILM012310595</span></p>
                            <button className='text-light theme-bg-color border-0 px-3 py-2 fw-bolder me-3'>
                                Book a Viewing
                            </button>
                            <div className='row mt-3'>
                                <div className='col-lg-6 my-lg-0 my-md-0 my-2'>
                                    <button className='w-100 text-dark border border-dark bg-transparent px-3 py-2 fw-bolder'>
                                        <span><IoIosHeartEmpty /></span> Save
                                    </button>
                                </div>
                                <div className='col-lg-6 my-lg-0 my-md-0 my-2'>
                                    <button className='w-100 text-dark border border-dark bg-transparent px-3 py-2 fw-bolder'>
                                    <span><CiShare1 /></span>Share
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <h1 className='text-center fw-bolder mb-5'>A little more about the property</h1>
                        <p className='text-center'>Originally designed by renowned architect Norman Shaw, this substantial home offers flexible living accommodation over three floors. To the front of the property is via a large gravel driveway with parking for several vehicles and a double garage. The kitchen and dining area is undoubtedly the heart of this home, with a large central island and countertop seating. The custom- built kitchen is paired with an elegant silestone quartz worktop and stainless steel fitted appliances. Each of the three further reception rooms flow from this space and they include a TV/family room, formal sitting room and a large dining room. There are two guest or annexe bedrooms with a shared bathroom accessed via a stairway above the garage, one of these rooms is currently in use as a home office. Throughout the main house the ceiling heights are impressive, alongside period features such as original fireplaces, cornicing and joinery.</p>
                        <p className='text-center'>To the first floor are two generous double bedrooms, one with ensuite bathroom as a principal suite, plus a family bathroom. There are two bedrooms to the top floor of the house, ideal as children’s bedrooms.</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main