import React from 'react'
import Header from './Header'
// import Footer from './Footer'
import HomeFooter from './Home/HomeFooter'
// import Topbar from './Topbar'

const Layout = ({children}) => {
    return (
        <>
            {/* <Topbar /> */}
            <Header />
            {children}
            <HomeFooter />
        </>
    )
}

export default Layout