import React from 'react'

const HomeFooter = () => {
  return (
    <>
      <section className='bg-dark p-5'>
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-2 mx-auto'>
                            <h4 className='text-white'>For Sale</h4>
                            <ul className='text-secondary list-inline nav-link mt-4'>
                                <li>Find a property to buy</li>
                                <li>Find a property to rent</li>
                                <li>Castles for Sale</li>
                                <li>Country Houses for Sale</li>
                                <li>Equestrian Property for Sale</li>
                            </ul>
                        </div>
                        <div className='col-lg-2 mx-auto'>
                            <h4 className='text-white'>To Rent</h4>
                            <ul className='text-secondary list-inline  mt-4'>
                                <li>Find a property to rent</li>
                                <li>Find investment property</li>
                            </ul>
                        </div>
                        <div className='col-lg-2 mx-auto'>
                            <h4 className='text-white'>Research</h4>
                            <ul className='text-secondary list-inline  mt-4'>
                                <li>Our App</li>
                                <li>Blog</li>
                                <li>Research Reports</li>
                            </ul>
                        </div>
                    </div>
                   
                </div>
            </section >
    </>
  )
}

export default HomeFooter
