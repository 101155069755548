import React from 'react'
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import { IoIosBed } from "react-icons/io";
import { GiBathtub, GiSofa } from "react-icons/gi";
import { Link } from 'react-router-dom';

const ListingCard = ({ data }) => {
    console.log(data);
    return (
        <>
            <Link to="/detail" className="text-decoration-none">
                <Card className='card-listing'>
                    <Carousel>
                        <Carousel.Item>
                            <img src={data?.images[0]} alt='' className='img-fluid' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={data?.images[1]} alt='' className='img-fluid' />
                        </Carousel.Item>
                    </Carousel>
                    <Card.Body>
                        <Card.Text className='mb-1'>{data?.name}</Card.Text>
                        <Card.Text className='mb-1'>
                            Guide price
                        </Card.Text>
                        <Card.Text className='mb-1'>
                            ZipCode : {data?.zipCode}
                        </Card.Text>
                        <Card.Title className='mb-1'>£{data?.price}</Card.Title>
                        <small>{data?.type}</small>
                        <br />
                        <small>{data?.squareFeet} sqft</small>
                        <div className='row mt-2'>
                            <div className='col-lg-4 col-md-4 col-4'>
                                <IoIosBed fontSize={30} />
                                <p>{data?.bedrooms}</p>
                            </div>
                            <div className='col-lg-4 col-md-4 col-4'>
                                <GiBathtub fontSize={30} />
                                <p>{data?.bathrooms}</p>
                            </div>
                            <div className='col-lg-4 col-md-4 col-4'>
                                <GiSofa fontSize={30} />
                                <p>{data?.livingRooms}</p>
                            </div>
                        </div>

                    </Card.Body>
                </Card>
            </Link>

        </>
    )
}

export default ListingCard