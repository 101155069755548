import React from 'react'
import './home.css'
import HomeHeader from '../../Layout/Home/HomeHeader'
import HeroSection from '../../Components/Home/HeroSection'
import HomeSection01 from '../../Components/Home/HomeSection01'
import HomeSection02 from '../../Components/Home/HomeSection02'
import HomeFooter from '../../Layout/Home/HomeFooter'

const Home = () => {
  return (
    <>
    <div className='home-banner'>
      <HomeHeader />
      <HeroSection />
      <HomeSection01 />
      <HomeSection02 />
      <HomeFooter />
    </div>
    </>
  )
}

export default Home

